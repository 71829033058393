// 79909
@import '../helpers/componentHeader';

// 58171
// 100031
// 105343
.homepage-disclaimers {
    background-color: $white-smoke;
    .disclaimers-container {
        padding: 20px 0px;
        .disclaimers-text {
            text-align: left;
            font-family: $primary-font;
            font-size: 14px;
            line-height: 26px;
            color: $gray;
            padding: 20px 0px 10px 15px;
            margin: 0px;
            span.mr-2 {
                margin-left: -14px;
            }
            @media (max-width: 768px) {
                font-size: 12px;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .experience-component:last-of-type {
        .disclaimer-section{
            padding-bottom: 0px !important;
        }
    }
}